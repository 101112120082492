<template>
  <PasswordSection />
</template>

<script>
import PasswordSection from '../components/PasswordSection.vue';
export default {
components:{
    PasswordSection,
}
}
</script>